import { Models } from "@services/api";
import { Duration } from "luxon";

import { Filters } from "@models/common";
import { RATING_FILTER_IDS } from "@constants/rating-filters";
import { must } from "@utils/must";

export const filterFacilities = (
  facilities: Models.FacilityCard[],
  filters: Filters,
): Models.FacilityCard[] => {
  if (filters.secondary.facilityType?.length) {
    facilities = facilities.filter((f) => {
      if (filters.secondary.facilityType?.includes(f.facilityType)) {
        return true;
      }

      return false;
    });
  }

  if (filters.secondary.features?.length) {
    filters.secondary.features.forEach((feature) => {
      if (feature === "faith") {
        facilities = facilities.filter(
          (f) => !!f.operatingInfoReligionAffiliation,
        );
      }
      if (feature === "meals") {
        facilities = facilities.filter((f) => f.nutritionInfoMealsServed);
      }
      if (feature === "vr") {
        facilities = facilities.filter((f) => f.toursInfoDataAvailable);
      }
      if (feature === "education") {
        facilities = facilities.filter(
          (f) =>
            f.servicesInfoMontessoriMethod ||
            f.servicesInfoWaldorfMethod ||
            f.servicesInfoRegioEmiliaMethod,
        );
      }
      if (feature === "tuition") {
        facilities = facilities.filter((f) => f.tuitionInfoCostPeriod);
      }
      if (feature === "media") {
        facilities = facilities.filter((f) => f.hasMedia);
      }
      if (feature === "language") {
        facilities = facilities.filter((f) => f.languages.length > 1);
      }
      if (feature === "backUp") {
        facilities = facilities.filter(
          (f) => f.hasBackUpCare || f.bookingActive,
        );
      }
      if (feature === "mobileParentApp") {
        facilities = facilities.filter(
          (f) =>
            f.mobileParentApp &&
            f.mobileParentApp !== Models.MobileParentApp.No,
        );
      }
      if (feature === "videoStreaming") {
        facilities = facilities.filter(
          (f) =>
            f.videoStreaming && f.videoStreaming !== Models.VideoStreaming.No,
        );
      }
    });
  }

  if (filters.secondary.additionalDesignation) {
    facilities = facilities.filter((f) => {
      if (filters.secondary.additionalDesignation === "cooperative-school") {
        return (
          f.additionalDesignations ===
          Models.AdditionalDesignations.CooperativeSchools
        );
      }
      if (filters.secondary.additionalDesignation === "nursery") {
        return (
          f.additionalDesignations === Models.AdditionalDesignations.Nursery
        );
      }
      return false;
    });
  }

  if (filters.secondary.localCorporations) {
    if (filters.secondary.localCorporations === true) {
      facilities = facilities.filter((f) => !!f.corporationId);
    } else {
      facilities = facilities.filter(
        (f) => f.corporationId === filters.secondary.localCorporations,
      );
    }
  }

  if (filters.secondary.tier) {
    facilities = facilities.filter(
      (f) => f.subscriptionTier === filters.secondary.tier,
    );
  }

  if (filters.secondary.rating) {
    facilities = facilities.filter((f) => {
      if (f.reviewsSummary.gradeOverallCount) {
        if (filters.secondary.rating === RATING_FILTER_IDS.Any) {
          return true;
        }

        return (
          Math.round(must(f.reviewsSummary.gradeOverall) * 10) / 10 >=
          parseFloat(must(filters.secondary.rating))
        );
      }

      return false;
    });
  }

  if (filters.secondary.opensBy) {
    const opensBy = filters.secondary.opensBy;

    facilities = facilities.filter((f) => {
      if (!f.schedule?.length) {
        return false;
      }

      const d = f.schedule[0];

      if (
        opensBy === "by7am" &&
        d.start <= Duration.fromObject({ hours: 7 }).as("seconds")
      ) {
        return true;
      }

      if (
        opensBy === "by630am" &&
        d.start <= Duration.fromObject({ hours: 6.5 }).as("seconds")
      ) {
        return true;
      }

      return false;
    });
  }

  if (filters.secondary.closesAfter) {
    const closesAfter = filters.secondary.closesAfter;

    facilities = facilities.filter((f) => {
      if (!f.schedule?.length) {
        return false;
      }

      const d = f.schedule[0];

      if (
        closesAfter === "after6pm" &&
        d.end > Duration.fromObject({ hours: 18 }).as("seconds")
      ) {
        return true;
      }
      return false;
    });
  }

  if (filters.secondary.weekendCare) {
    // const weekendCareFilter = filters.secondary.weekendCare;

    facilities = facilities.filter((f) => {
      if (!f.bookingActive) {
        return false;
      }

      return true;

      // if (weekendCareFilter === "all") {
      //   return true;
      // }

      // if (f.bookingSessions?.some((s) => (s.status === "active" || s.status === "activeUpcoming") &&  s.weekday === weekendCareFilter)) {
      //   return true;
      // }

      return false;
    });
  }

  return facilities;
};
