import { PrimaryFilterParam, SecondaryFilterParam } from "@models/common";

export const primaryFilterParams = [
  PrimaryFilterParam.FacilityName,
  PrimaryFilterParam.Address,
  PrimaryFilterParam.Phone,
  PrimaryFilterParam.Folder,
  PrimaryFilterParam.Corporation,
  PrimaryFilterParam.AccountId,
  PrimaryFilterParam.Ids,
];

export const secondaryFilterParams = [
  SecondaryFilterParam.FacilityType,
  SecondaryFilterParam.Features,
  SecondaryFilterParam.Rating,
  SecondaryFilterParam.OpensBy,
  SecondaryFilterParam.ClosesAfter,
  SecondaryFilterParam.WeekendCare,
  SecondaryFilterParam.LocalCorporations,
  SecondaryFilterParam.Tier,
  SecondaryFilterParam.AdditionalDesignation,
];

export const filterParams = [...primaryFilterParams, ...secondaryFilterParams];
