import React, { Fragment } from "react";
import { css as emotionCss, CSSObject } from "@emotion/css";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";
import { useQuery } from "react-query";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { ROUTES } from "@constants";
import { createCss } from "./styles";
import { useUserStore } from "@store/UserStore";
import { ReactGAEventWait } from "@helpers/ga";

import LogoImage from "@images/pdo_logo_teal_white_logo_teal_white.svg";
import { API } from "@services/api";
import { QUERIES } from "@constants/queries";
import { isCorpBenefit, isParent } from "@selectors";

interface IProps {
  open: boolean;
  onChange: (state: boolean) => void;
}

const MainMenu: React.FC<IProps> = ({ open, onChange }) => {
  const history = useHistory();
  const location = useLocation();
  const [{ claimed, favorites, user }, { logout }] = useUserStore();
  const css = createCss();
  const { search } = location;
  const { data: chats } = useQuery(
    [QUERIES.USER_CHATS, user?.id],
    () => API.contactRequests.getList({ parentUserId: user?.id }),
    { enabled: !!user?.id },
  );

  function renderMenu() {
    const sideList = (
      <List css={css.menu}>
        <ListItem
          button
          onClick={() => {
            ReactGAEventWait({
              action: "LogoClick",
              category: "Header",
            });
            history.push(ROUTES.HOMEPAGE);
          }}
          css={css.logoMenuItem}
          data-test="logo"
        >
          <img src={LogoImage} alt="logo" />
        </ListItem>

        <ListItem
          button
          data-test="weekend-experience-link"
          onClick={() => {
            ReactGAEventWait({
              action: "WeekendExperienceClick",
              category: "Header",
            });
            history.push(ROUTES.ACTIVITIES);
          }}
          css={css.menuItem}
        >
          <ListItemText primary="Account overview" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            ReactGAEventWait({
              action: "MyProfileClick",
              category: "Header",
            });
            history.push(ROUTES.PROFILE);
          }}
          data-test="my-profile-link"
          css={css.menuItem}
          className="gold nested lighted"
        >
          <ListItemText primary="My profile" />
        </ListItem>
        {!!claimed.length && (
          <ListItem
            button
            onClick={() => {
              history.push("map?accountId=-1");
            }}
            css={css.menuItem}
            className="gold nested lighted"
          >
            <ListItemText primary={`Claimed schools (${claimed.length})`} />
          </ListItem>
        )}
        {!!favorites.length && (
          <ListItem
            button
            onClick={() => {
              history.push("/map?folder=favorites");
            }}
            css={css.menuItem}
            className="gold nested lighted"
          >
            <ListItemText primary={`Favorite schools (${favorites.length})`} />
          </ListItem>
        )}
        {!!chats?.length && (
          <ListItem
            button
            onClick={() => {
              history.push("/chats");
            }}
            css={css.menuItem}
            className="gold nested lighted"
          >
            <ListItemText primary={`School chats (${chats.length})`} />
          </ListItem>
        )}
        <ListItem
          button
          onClick={() => {
            history.push("/support");
          }}
          css={css.menuItem}
          className="gold nested lighted"
        >
          <ListItemText primary="Contact support" />
        </ListItem>

        {!claimed.length && (
          <Fragment>
            <ListItem button css={css.menuItem}>
              <ListItemText primary="Parent Pass" />
            </ListItem>

            {isParent(user) && (
              <>
                <ListItem
                  button
                  onClick={() => {
                    ReactGAEventWait({
                      action: "ChildrenListClick",
                      category: "Header",
                    });
                    history.push(ROUTES.CHILDREN_LIST);
                  }}
                  data-test="child-profile-link"
                  css={css.menuItem}
                  className="gold nested lighted"
                >
                  <ListItemText primary="Child profile(s)" />
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    ReactGAEventWait({
                      action: "PaymentMethodClick",
                      category: "Header",
                    });
                    history.push(ROUTES.WALLET);
                  }}
                  data-test="payments-link"
                  css={css.menuItem}
                  className="gold nested lighted"
                >
                  <ListItemText primary="Payment method" />
                </ListItem>
              </>
            )}

            <ListItem
              button
              onClick={() => {
                ReactGAEventWait({
                  action: "ReservationHistoryClick",
                  category: "Header",
                });
                if (isCorpBenefit(user)) {
                  history.push(
                    `/dashboard/corporations/${user?.account.id}/reservations`,
                  );
                } else {
                  history.push(ROUTES.ACTIVITIES);
                }
              }}
              css={css.menuItem}
              className="gold nested lighted"
              data-test="weekend-care-history-link"
            >
              <ListItemText primary="Reservation history" />
            </ListItem>

            {isParent(user) && (
              <ListItem
                button
                onClick={() => {
                  ReactGAEventWait({
                    action: "RegistrationFormsClick",
                    category: "Header",
                  });
                  history.push("/registration-forms");
                }}
                css={css.menuItem}
                className="gold nested lighted"
                data-test="registration-forms-link"
              >
                <ListItemText primary="Registration forms" />
              </ListItem>
            )}
          </Fragment>
        )}

        <ListItem
          button
          onClick={() => {
            history.replace({
              search: qs.stringify({
                ...qs.parse(search),
                accountId: undefined,
                folder: undefined,
              }),
            });
            ReactGAEventWait({
              action: "LogOutClick",
              category: "Header",
            });
            logout();
          }}
          data-test="logout-link"
          css={css.menuItem}
        >
          <ListItemText primary="Log out" />
        </ListItem>
      </List>
    );

    return (
      <Fragment>
        <SwipeableDrawer
          open={open}
          onClose={() => {
            onChange(false);
          }}
          onOpen={() => onChange(true)}
          data-test="swipeable-menu"
          classes={{
            paper: emotionCss(css.paper as CSSObject),
          }}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={() => {
              onChange(false);
            }}
            onKeyDown={() => {
              onChange(false);
            }}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </Fragment>
    );
  }

  function renderNotAuthMenu() {
    const sideList = (
      <List css={css.menu}>
        <ListItem
          button
          onClick={() => {
            ReactGAEventWait({
              action: "LogoClick",
              category: "Header",
            });
            history.push(ROUTES.HOMEPAGE);
          }}
          css={css.logoMenuItem}
          data-test="logo"
        >
          <img src={LogoImage} alt="logo" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            ReactGAEventWait({
              action: "LogInClick",
              category: "Header",
            });
            history.push({
              pathname: ROUTES.LOGIN_FORM,
            });
          }}
          data-test="sign-in-link"
          css={css.menuItem}
          className="lighted"
        >
          <ListItemText primary="Log in" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            ReactGAEventWait({
              action: "SignUpClick",
              category: "Header",
            });
            history.push({
              pathname: ROUTES.SIGNUP_FORM,
            });
          }}
          data-test="sign-up-link"
          css={css.menuItem}
          className="lighted"
        >
          <ListItemText primary="Sign up" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/support");
          }}
          css={css.menuItem}
          className="lighted"
        >
          <ListItemText primary="Contact support" />
        </ListItem>
      </List>
    );

    return (
      <Fragment>
        <SwipeableDrawer
          open={open}
          onClose={() => onChange(false)}
          onOpen={() => onChange(true)}
          data-test="swipeable-menu"
          classes={{
            paper: emotionCss(css.paper as CSSObject),
          }}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={() => onChange(false)}
            onKeyDown={() => onChange(false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </Fragment>
    );
  }

  if (user) {
    return renderMenu();
  }

  return renderNotAuthMenu();
};

export default MainMenu;
