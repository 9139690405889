import React, { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import Badge from "@material-ui/core/Badge";
import qs from "query-string";

import useStyles from "./styles";
import { Input } from "@ui-kit/InputFields";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import { GEO_API } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";
import { useMapStore } from "@store/MapStore";
import { AxiosError } from "axios";
import { ServerError } from "@models/common";
import { useViewport } from "@hooks/useViewport";

interface IProps {
  onMenuOpen: (state: boolean) => void;
}

const SearchInput: React.FC<IProps> = ({ onMenuOpen }) => {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const { search } = useLocation();
  const [, { setNotification }] = useNotificationStore();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const mapStore = useMapStore(false);
  const { isMobile } = useViewport();
  const [showClearInput, setShowClearInput] = useState(() =>
    Boolean(searchString),
  );
  const classes = useStyles();

  if (!mapStore) {
    return null;
  }

  const [
    { facilities, searchString, filterIsActive, filtersCounter, filters },
    {
      openFilters,
      setFacilityList,
      setSearchString,
      filterFacilities,
      setShowFindToClaimDialog,
    },
  ] = mapStore;

  async function findFacilities() {
    if (!/^[0-9]+$/.exec(searchString.trim())) {
      trackEvent({
        action: "Search submit",
        value: searchString,
        type: "facilityName",
      });

      history.replace({
        search: qs.stringify({
          ...qs.parse(search),
          findFacilityName: searchString,
          findFacilityView: "name",
        }),
      });
      setShowFindToClaimDialog(true);

      return;
    }
    if (
      filters.primary.accountId ||
      filters.primary.corporation ||
      filters.primary.folder
    ) {
      trackEvent({
        action: "Search submit",
        value: searchString,
        type: "zipFilter",
      });
      setFacilityList(filterFacilities(facilities, true));
    } else {
      try {
        trackEvent({
          action: "Search submit",
          value: searchString,
          type: "zipSearch",
        });

        const {
          data: { latitude, longitude },
        } = await GEO_API.geoZipsByIdGet({
          id: searchString.trim(),
        });

        history.push({
          pathname: "/map",
          search: qs.stringify({
            lat: latitude,
            lng: longitude,
            zip: searchString,
            ...filters.secondary,
          }),
        });
      } catch (e) {
        const error: AxiosError<{ errors: ServerError[] }> = e;
        const errors = error?.response?.data?.errors;

        if (errors) {
          setNotification({
            // message: error.response.data.errors[0].title,
            message: "Zip code not recognized",
            type: "error",
          });
        } else {
          throw error;
        }
      }
    }

    inputRef.current?.blur();
  }

  return (
    <Input
      ref={inputRef}
      placeholder="Enter zip or name"
      onChange={(event) => {
        setShowClearInput(false);
        setSearchString(event.target.value);
      }}
      selectOnFocus
      value={searchString}
      onKeyDown={async (event) => {
        if (event.keyCode === 13) {
          await findFacilities();
          setShowClearInput(true);
        }
      }}
      startAdornment={
        isMobile ? (
          <InputAdornment position="start">
            <IconButton
              onClick={() => onMenuOpen(true)}
              data-test="main-menu-btn"
            >
              <MenuIcon className={classes.mobileIcon} />
            </IconButton>
          </InputAdornment>
        ) : undefined
      }
      endAdornment={
        <InputAdornment position="end">
          {showClearInput && searchString !== "" ? (
            <IconButton
              onClick={() => {
                setSearchString("");
                setFacilityList(filterFacilities(facilities));
                setShowClearInput(false);
              }}
              className={classes.searchIcon}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={async () => {
                await findFacilities();
                setShowClearInput(true);
              }}
              className={classes.searchIcon}
              data-test="zip-code-search-button"
            >
              <SearchIcon />
            </IconButton>
          )}
          {isMobile && (
            <IconButton
              onClick={() => {
                openFilters();
              }}
            >
              <Badge
                color="primary"
                badgeContent={filtersCounter}
                invisible={!filterIsActive.current}
              >
                <TuneIcon
                  color="primary"
                  className={clsx(classes.mobileIcon, "filter")}
                />
              </Badge>
            </IconButton>
          )}
        </InputAdornment>
      }
      inputClasses={{
        input: classes.searchInput,
        root: classes.searchInputRoot,
      }}
      formControlClasses={{
        root: clsx(classes.searchFormControl, {
          filtersActive: filterIsActive.current,
        }),
      }}
      disableUnderline
      data-test="zip-code-search-field"
    />
  );
};

export default SearchInput;
