import keyBy from "lodash/keyBy";

export enum RATING_FILTER_IDS {
  Any = "any",
  Good = "7",
  Great = "8.5",
}

export type RatingFilter = {
  id: RATING_FILTER_IDS;
  name: string;
  chipName: string;
};

export const RATING_FILTERS: RatingFilter[] = [
  {
    chipName: "Any rating",
    id: RATING_FILTER_IDS.Any,
    name: "Show all rated schools",
  },
  {
    chipName: "7/10+",
    id: RATING_FILTER_IDS.Good,
    name: "Show ratings 7/10 or higher",
  },
  {
    chipName: "8.5/10+",
    id: RATING_FILTER_IDS.Great,
    name: "Show ratings 8.5/10 or higher",
  },
];

export const ratingFiltersMap = keyBy(RATING_FILTERS, "id");
