import { createUseStyles } from "react-jss";
import { fade } from "@material-ui/core/styles";

import { ITheme } from "@theme";
import { loadWebp } from "@helpers/loadWebp";

import backgroundImageWebp from "@images/background.webp";
import backgroundImageJpg from "@images/background.jpg";

const styles = (theme: ITheme) => ({
  root: {
    [theme.mediaRequests.notMobile]: {
      background: `url(${loadWebp(backgroundImageWebp, backgroundImageJpg)})`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: "5px 10px 5px 465px",
    },

    [theme.mediaRequests.mobile]: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",

      "& .clear": {
        color: "white",
        fontWeight: 600,
        textTransform: "uppercase",
      },
    },
  },

  filterChip: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,

    "& svg": {
      color: "inherit",
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.light,

      "& svg": {
        color: "inherit",
      },
    },

    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },

    "&:not(:last-child)": {
      marginRight: 10,
    },

    "&.daycare-center": {
      backgroundColor: theme.palette.pink.main,

      "&:hover": {
        backgroundColor: theme.palette.pink.light,

        "& svg": {
          color: "inherit",
        },
      },

      "&:focus": {
        backgroundColor: theme.palette.pink.main,
      },
    },

    "&.pre-school": {
      backgroundColor: theme.palette.success.main,

      "&:hover": {
        backgroundColor: theme.palette.success.light,

        "& svg": {
          color: "inherit",
        },
      },

      "&:focus": {
        backgroundColor: theme.palette.success.main,
      },
    },

    "&.oshc": {
      backgroundColor: "#2e2c2c",
      border: "1px solid white",

      "&:hover": {
        backgroundColor: theme.palette.augmentColor({ main: "#2e2c2c" }).light,

        "& svg": {
          color: "inherit",
        },
      },

      "&:focus": {
        backgroundColor: "#2e2c2c",
      },
    },
  },

  mobileChipContainer: {
    position: "relative",
  },

  mobileChipsPopover: {
    bottom: 0,
    left: 0,
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 10,

    "& .overlay": {
      bottom: 0,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },

    "& .chips": {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      left: 5,
      position: "absolute",
      top: 105,
    },

    "& .chip": {
      "&:not(:last-child)": {
        marginBottom: 10,
        marginRight: 0,
      },
    },
  },
});

export default createUseStyles(styles, { name: "HeaderFilters" });
