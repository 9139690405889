import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type SchoolType = {
  id: Models.FacilityTypeID;
  title: string;
  chipName: string;
};

export const schoolTypes: SchoolType[] = [
  {
    chipName: "Childcare",
    id: Models.FacilityTypeID.DaycareCenter,
    title: "Childcare center",
  },
  {
    chipName: "Private school",
    id: Models.FacilityTypeID.PrivateSchool,
    title: "Private school",
  },
  {
    chipName: "Public school",
    id: Models.FacilityTypeID.PublicSchool,
    title: "Public school",
  },
  {
    chipName: "Head start",
    id: Models.FacilityTypeID.HeadstartSchool,
    title: "Head start",
  },
  {
    chipName: "Charter school",
    id: Models.FacilityTypeID.CharterSchool,
    title: "Charter school",
  },
  {
    chipName: "Preschool",
    id: Models.FacilityTypeID.PreSchool,
    title: "Preschool",
  },
  {
    chipName: "After school",
    id: Models.FacilityTypeID.Oshc,
    title: "After-school care",
  },
];

export const schoolTypesMap = keyBy(schoolTypes, "id");
