import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import qs from "query-string";
import clsx from "clsx";
import { useTracking } from "react-tracking";

import { Input } from "@ui-kit/InputFields";
import Typography from "@ui-kit/Typography";
import { ReactGAEventWait } from "@helpers/ga";
import { createCss } from "./styles";
import Button from "@ui-kit/Button";
import { GEO_API } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";
import { Amplitude } from "@services/amplitude";

type SearchInputProps = {
  position?: "header" | "page";
};

export const SearchInput: React.FC<SearchInputProps> = ({
  position = "page",
}) => {
  const { trackEvent } = useTracking();
  const history = useHistory();
  const [, { setUnknownErrorNotification, setNotification }] =
    useNotificationStore();
  const [zipcode, setZipcode] = useState("");
  const [showUserLocationError, setShowUserLocationError] = useState(false);
  const css = createCss();

  async function searchZipcode() {
    if (!zipcode) {
      trackEvent({
        action: "Search by user location",
      });
      searchMyLocation();
      return;
    }

    try {
      ReactGAEventWait({
        action: "SearchByZip",
        category: "Homepage",
        label: zipcode,
      });
      trackEvent({
        action: "Search zip",
        value: zipcode,
      });

      const {
        data: { latitude, longitude },
      } = await GEO_API.geoZipsByIdGet({
        id: zipcode,
      });

      ReactGAEventWait({
        action: "SearchByZipSuccess",
        category: "Homepage",
        label: `${longitude}, ${latitude}`,
      });
      goToMap([longitude, latitude], zipcode);
    } catch (error) {
      const errors = error?.response?.data?.errors;

      ReactGAEventWait({
        action: "SearchByZipError",
        category: "Homepage",
        label: errors[0].title,
      });

      if (error?.response?.status === 400) {
        setNotification({
          // message: errors[0].title,
          message: "Zip code not recognized",
          type: "error",
        });
      } else {
        setUnknownErrorNotification();
        throw error;
      }
    }
  }

  function goToMap(coords: [number, number], zip?: string) {
    history.push({
      pathname: "/map",
      search: qs.stringify({
        lat: coords[1],
        lng: coords[0],
        zip,
      }),
    });
  }

  const searchMyLocation = () => {
    ReactGAEventWait({
      action: "SearchMyLocation",
      category: "Homepage",
    });
    navigator?.geolocation?.getCurrentPosition(
      (position) => {
        ReactGAEventWait({
          action: "SearchMyLocationSuccess",
          category: "Homepage",
          label: `${position.coords.longitude},${position.coords.latitude}`,
        });

        goToMap([position.coords.longitude, position.coords.latitude]);
      },
      (error) => {
        ReactGAEventWait({
          action: "SearchMyLocationDenied",
          category: "Homepage",
          label: `${error.message}`,
        });

        setShowUserLocationError(true);
      },
      {
        maximumAge: 0,
        timeout: 5000,
      },
    );
  };

  return (
    <div
      css={css.container}
      className={clsx({
        [`${position}`]: true,
      })}
    >
      <Input
        placeholder="Search by zip code"
        formControlClasses={{
          root: clsx("formControlRoot", {
            [`${position}`]: true,
          }),
        }}
        data-test="search-zip-field"
        disableUnderline
        selectOnFocus
        onChange={(event) => {
          setZipcode(event.target.value);
        }}
        autoComplete="new-password"
        variant="number"
        maxLength={5}
        value={zipcode}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            void searchZipcode();
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              data-test="my-location-button"
              css={css.mainLocation}
              onClick={searchMyLocation}
            >
              <MyLocationIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Button
        color="yellow"
        variant="contained"
        data-test="search-zip-submit"
        css={css.button}
        onClick={searchZipcode}
        textTransform="none"
        bolded
      >
        Show facilities near me
      </Button>

      {showUserLocationError && (
        <Snackbar
          open
          onClose={() => setShowUserLocationError(false)}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
          className="g-snackbar"
          ContentProps={{
            "aria-describedby": "user-location-error",
          }}
          message={
            <Typography id="user-location-error" variant="body1">
              We do not have permission to use your location
            </Typography>
          }
          action={
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => setShowUserLocationError(false)}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      )}
    </div>
  );
};
