import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  logoMenuItem: {
    backgroundColor: "#131314",
    borderBottom: "3px solid #19191b",
    justifyContent: "center",
    padding: "15px 0",

    "& img": {
      width: 100,
    },
  },

  menu: {
    padding: 0,
    width: 300,
  },

  menuItem: (theme) => ({
    paddingLeft: 30,

    "&.nested": {
      paddingLeft: 50,
    },

    "&.lighted": {
      backgroundColor: "#19191b",
    },

    "&.gold": {
      color: theme.palette.yellow.main,
    },
  }),

  paper: {
    backgroundColor: "#131314",
    color: "white",
  },

  claim: (theme) => ({
    backgroundColor: "#01eeda",
    border: "1px solid #01eeda",
    borderRadius: 12,
    color: theme.palette.text.primary,
    marginTop: 15,
    padding: "5px 20px",
  }),
});
