import { RATING_FILTER_IDS } from "@constants/rating-filters";
import { Models } from "@services/api";

export enum PrimaryFilterParam {
  FacilityName = "facilityName",
  Address = "address",
  Folder = "folder",
  Phone = "phone",
  Corporation = "corporation",
  AccountId = "accountId",
  Ids = "ids",
}

export enum SecondaryFilterParam {
  FacilityType = "facilityType",
  Features = "features",
  Rating = "rating",
  WeekendCare = "weekendCare",
  LocalCorporations = "localCorporations",
  Tier = "tier",
  AdditionalDesignation = "additionalDesignation",
  OpensBy = "opensBy",
  ClosesAfter = "closesAfter",
}

export type PrimaryFilters = {
  [PrimaryFilterParam.Folder]?: string;
  [PrimaryFilterParam.AccountId]?: number;
  [PrimaryFilterParam.Corporation]?: number;
  [PrimaryFilterParam.Phone]?: string;
  [PrimaryFilterParam.Address]?: string;
  [PrimaryFilterParam.FacilityName]?: string;
  [PrimaryFilterParam.Ids]?: string;
};

export type FilterFeature =
  | "vr"
  | "media"
  | "meals"
  | "faith"
  | "tuition"
  | "education"
  | "language"
  | "backUp"
  | "videoStreaming"
  | "mobileParentApp";
export type FilterAdditionalDesignation = "cooperative-school" | "nursery";
export type FilterOpensBy = "by630am" | "by7am";
export type FilterClosesAfter = "after6pm";

export type SecondaryFilters = {
  [SecondaryFilterParam.AdditionalDesignation]?: FilterAdditionalDesignation;
  [SecondaryFilterParam.FacilityType]?: Models.FacilityTypeID[];
  [SecondaryFilterParam.Features]?: FilterFeature[];
  [SecondaryFilterParam.OpensBy]?: FilterOpensBy;
  [SecondaryFilterParam.ClosesAfter]?: FilterClosesAfter;
  [SecondaryFilterParam.Rating]?: RATING_FILTER_IDS;
  [SecondaryFilterParam.WeekendCare]?: Models.WeekDay | "all";
  [SecondaryFilterParam.LocalCorporations]?: true | number;
  [SecondaryFilterParam.Tier]?: Models.SubscriptionTier;
};

export type Filters = {
  primary: PrimaryFilters;
  secondary: SecondaryFilters;
};

type ServerErrorCode =
  | "validation_required"
  | "validation_is_url"
  | "validation_min_greater_equal_than_required"
  | "validation_invalid_min_date"
  | "validation_max_less_equal_than_required"
  | "validation_is_email"
  | "validation_invalid_weekday"
  | "validation_invalid_min_date"
  | "validation_invalid_zip_code"
  | "validation_length_invalid"
  | "validation_length_out_of_range"
  | "validation_booking_config_conflicts"
  | "validation_invalid_only_datetime"
  | "age_unit_conflict"
  | "exists"
  | "dataseс_banned";

export type ServerError = {
  code: ServerErrorCode;
  source: {
    pointer?: string;
  };
  title: string;
  meta?: {
    [key: string]: any;
  };
};
