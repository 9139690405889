import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

import backgroundImageJpg from "@images/background.jpg";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    backgroundColor: "#131314",
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "relative",
    width: "100%",
    zIndex: 3000,

    "&.fixed": {
      left: 0,
      position: "fixed",
      top: 0,
      zIndex: 99,
    },

    "&.homepage": {
      "& $navigationContainer": {
        margin: "0 auto",
        maxWidth: 1240,
      },
    },
  }),

  navigationContainer: {
    alignContent: "center",
    display: "flex",
    height: 56,
    padding: "0 40px",
    width: "100%",
  },

  logoWrapper: {
    alignItems: "center",
    color: "#00d5bc",
    display: "inline-flex",
    justifyContent: "center",
    width: 90,
    marginRight: 40,

    "& img": {
      width: "100%",
    },
  },

  link: {
    alignItems: "center",
    background: "none",
    border: "none",
    color: "white",
    cursor: "pointer",
    display: "flex",
    fontSize: 16,
    fontWeight: 500,
    height: "100%",
    lineHeight: "18px",
    outline: "none",
    padding: "0 20px",
    whiteSpace: "nowrap",

    "& svg": {
      marginLeft: 3,
    },
  },

  findLink: (theme) => ({
    border: `1px solid ${theme.palette.yellow.main}`,
    borderRadius: 12,
    color: theme.palette.yellow.main,
    height: 40,
    marginLeft: 20,
    paddingRight: "20px !important",
  }),

  claimLink: (theme) => ({
    backgroundColor: "#01eeda",
    border: "1px solid #01eeda",
    borderRadius: 12,
    color: theme.palette.text.primary,
    height: 40,
    paddingRight: "20px !important",

    [theme.mediaRequests.notMobile]: {
      margin: "0 20px",
    },
  }),

  navigation: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    margin: "0 -20px",

    "&.right": {
      justifyContent: "flex-end",

      "& $link:last-child": {
        paddingRight: 0,
      },
    },
  },

  menuTrigger: {
    alignItems: "center",
    backgroundColor: "#131314",
    border: "none",
    color: "black",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    height: 56,
    justifyContent: "center",
    marginLeft: -20,
    outline: "none",
    padding: 0,
    width: 56,

    "& span, &:after, &:before": {
      background: "white",
      borderRadius: 2,
      content: "''",
      height: 2,
      margin: 3,
      width: 24,
    },
  },

  searchContainer: (theme) => ({
    [theme.mediaRequests.mobile]: {
      left: 0,
      padding: 5,
      position: "absolute",
      top: 0,
      width: "100%",
      zIndex: 15,

      "&.filtersActive": {
        background: `url(${backgroundImageJpg})`,
        boxShadow: "0 1px 2px rgba(0, 0, 0, .2)",
      },
    },
  }),

  menuItem: (theme) => ({
    minWidth: 180,

    "&.nested": {
      paddingLeft: 36,
      paddingRight: 30,
    },

    "&.lighted": {
      backgroundColor: "#19191b",
    },

    "&.gold": {
      color: theme.palette.yellow.main,
    },
  }),
});
