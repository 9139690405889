import isBrowser from "./is-browser";

let support: boolean;

export const isWebpSupported = (): boolean => {
  if (typeof support !== "undefined") {
    return support;
  }

  try {
    support =
      document
        .createElement("canvas")
        .toDataURL("image/webp")
        .startsWith("data:image/webp") || false;
  } catch (error) {
    support = false;
  }

  return support;
};

export const loadWebp = (webpPath: string, fallback: string): string => {
  if (!isBrowser()) {
    return "";
  }

  if (isWebpSupported()) {
    return webpPath;
  }

  return fallback;
};
