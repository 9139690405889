import React, { useCallback, useRef, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import qs from "query-string";
import values from "lodash/values";
import isFinite from "lodash/isFinite";

import { filterFacilities as filterFacilitiesHelper } from "@helpers/filterFacilities";
import { FiltersSections } from "@components/Filters";
import { Models, FACILITY_API, FACILITY_CARD_API } from "@services/api";
import { useRootStore } from "../../store/RootStore";
import {
  FilterAdditionalDesignation,
  FilterClosesAfter,
  FilterFeature,
  FilterOpensBy,
  Filters,
} from "@models/common";
import { RATING_FILTER_IDS } from "@constants/rating-filters";
import {
  filterParams,
  primaryFilterParams,
  secondaryFilterParams,
} from "./filterParams";

interface PathParams {
  facilityId: string;
}

const DISTANCE_FACTOR = 1.60934;

const defaultCoords: MapCenter = {
  lat: 40.77356489999999,
  lng: -73.9565551,
};

const getFirstMeaningWord = (str: string): string => {
  const prepositions = [
    "a",
    "the",
    "on",
    "in",
    "at",
    "but",
    "by",
    "for",
    "into",
    "of",
    "off",
    "onto",
    "to",
    "with",
  ];
  const cleanStr = normalizeString(str);
  const parts = cleanStr.split(" ");

  if (parts.length === 1) {
    return parts[0];
  }

  return parts.find((s) => !prepositions.includes(s)) || "";
};

const normalizeString = (str: string) => {
  return str.replace(/[^a-z\d\s]/gi, "").toLowerCase();
};

const getSecondFiltersSequence = (
  params: qs.ParsedQuery,
  currentSequence: SecondFilterSequence,
) => {
  let result = currentSequence;

  secondaryFilterParams.forEach((f) => {
    const v = params[f];

    if (v) {
      if (typeof v === "string") {
        const prevParamCount = result.filter((c) => c.type === f).length;
        const i = result.findIndex((c) => {
          if (prevParamCount > 1) {
            return c.type === f && c.value === v;
          }
          return c.type === f;
        });

        if (i !== -1) {
          result[i] = {
            type: f,
            value: v,
          };
        } else {
          result.push({
            type: f,
            value: v,
          });
        }
      } else if (Array.isArray(v)) {
        v.forEach((v) => {
          const i = result.findIndex((c) => c.type === f && c.value === v);

          if (i !== -1) {
            result[i] = {
              type: f,
              value: v,
            };
          } else {
            result.push({
              type: f,
              value: v,
            });
          }
        });
      }
    } else {
      result = result.filter((c) => c.type !== f);
    }
  });

  result = result.filter((c) => {
    const v = params[c.type];

    if (!v) {
      return false;
    }

    if (Array.isArray(v)) {
      if (!v.includes(c.value)) {
        return false;
      }
    } else {
      if (v !== c.value) {
        return false;
      }
    }

    return true;
  });

  return result;
};

type MapCenter = { lng: number; lat: number };
type SecondFilterSequence = {
  type: string;
  value: string;
}[];

export type MapStore = {
  neighbourhoodsShown: boolean;
  districtShown: boolean;
  filtersState: {
    opened: boolean;
    defaultSection: FiltersSections | null;
  };
  showFindToClaimDialog: boolean;
  showPoIs: boolean;
  showPoisLegendModal: boolean;
  activeFacility: Models.Facility | null;
  hoveredFacilityId: string | null;
  sponsoredFiltersOpened: boolean;
  emptySearchVisible: boolean;
  isLoading: boolean;
  searchAreaButtonVisible: boolean;
  facilities: Models.FacilityCard[];
  facilityList: Models.FacilityCard[] | null;
  searchMiles: number;
  searchString: string;
  anchorMapCenter: { lng: number; lat: number };
  filters: Filters;
  secondFiltersSequence: SecondFilterSequence;
  userPositionFetching: boolean;
  notLoadedWarningOpened: boolean;
};

export type MapStoreWithRefs = MapStore & {
  shouldReloadData: React.MutableRefObject<boolean>;
  shouldFitMap: React.MutableRefObject<boolean>;
  // TODO rework
  searchMilesRef: React.MutableRefObject<number>;
  // TODO rework
  anchorMapCenterRef: React.MutableRefObject<MapCenter>;
  globalFiltersAreActive: React.MutableRefObject<boolean>;
  secondaryFiltersAreActive: React.MutableRefObject<boolean>;
  filterIsActive: React.MutableRefObject<boolean>;
  filtersCounter: number;
};

export type MapActions = {
  setNeighbourhoodsShown: (state: boolean) => void;
  closeFilters: () => void;
  openFilters: (defaultSection?: FiltersSections | null) => void;
  setShowPoisLegendModal: (state: boolean) => void;
  setActiveFacility: (facility: Models.Facility | null) => void;
  saveFacility: (facility: Models.Facility | null) => Promise<void>;
  setShowFindToClaimDialog: (state: boolean) => void;
  setShowPoIs: (state: boolean) => void;
  setHoveredFacilityId: (id: string | null) => void;
  setDistrictShown: (state: boolean) => void;
  setEmptySearchVisible: (state: boolean) => void;
  getFacilityCardsList: () => void;
  setSearchAreaButtonVisible: (state: boolean) => void;
  fetchData: () => Promise<void>;
  setSponsoredFiltersOpened: (state: boolean) => void;
  setFacilityList: (list: Models.FacilityCard[]) => void;
  clearFilters: () => void;
  setSearchString: (str: string) => void;
  filterFacilities: (
    list: Models.FacilityCard[],
    withZip?: boolean,
  ) => Models.FacilityCard[];
  setAnchorMapCenter: (coords: MapCenter) => void;
  setFilters: (params: qs.ParsedQuery, initial?: boolean) => void;
  setUserPositionFetching: (state: boolean) => void;
  setNotLoadedWarningOpened: (state: boolean) => void;
};

const defaultState: MapStore = {
  activeFacility: null,
  anchorMapCenter: defaultCoords,
  districtShown: false,
  emptySearchVisible: false,
  facilities: [],
  facilityList: null,
  filters: {
    primary: {},
    secondary: {},
  },
  filtersState: {
    defaultSection: null,
    opened: false,
  },
  hoveredFacilityId: null,
  isLoading: false,
  neighbourhoodsShown: false,
  notLoadedWarningOpened: false,
  searchAreaButtonVisible: false,
  searchMiles: 1,
  searchString: "",
  secondFiltersSequence: [],
  showFindToClaimDialog: false,
  showPoIs: false,
  showPoisLegendModal: false,
  sponsoredFiltersOpened: false,
  userPositionFetching: false,
};

const MapStoreContext = React.createContext<
  [MapStoreWithRefs, MapActions] | null
>(null);

export const MapStoreProvider: React.FC<{
  initialState?: Partial<MapStore>;
}> = ({ children, initialState = {} }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { facilityId } = useParams<PathParams>();
  const root = useRootStore();
  const shouldFitMapRef = useRef(false);
  const shouldReloadDataRef = useRef(false);
  const searchMilesRef = useRef(1);
  const anchorMapCenterRef = useRef(defaultCoords);
  const filtersRef = useRef<Filters>({ primary: {}, secondary: {} });
  const globalFiltersAreActive = useRef(false);
  const secondaryFiltersAreActive = useRef(false);
  const filterIsActive = useRef(false);
  const searchStringRef = useRef("");

  const formatFilters = useCallback((params: qs.ParsedQuery) => {
    const parsedAccountId = parseInt(params.accountId as string, 10);
    const parsedCorporationId = parseInt(params.corporation as string, 10);

    const newFilters: Filters = {
      primary: {
        accountId: isFinite(parsedAccountId) ? parsedAccountId : undefined,
        address: params.address as string,
        corporation: isFinite(parsedCorporationId)
          ? parsedCorporationId
          : undefined,
        facilityName: params.facilityName as string,
        folder: params.folder as string,
        ids: params.ids as string,
        phone: params.phone as string,
      },
      secondary: {
        additionalDesignation:
          params.additionalDesignation as FilterAdditionalDesignation,
        closesAfter: params.closesAfter as FilterClosesAfter,
        facilityType:
          typeof params.facilityType === "string"
            ? [params.facilityType as Models.FacilityTypeID]
            : (params.facilityType as Models.FacilityTypeID[]) || undefined,
        features:
          typeof params.features === "string"
            ? [params.features as FilterFeature]
            : (params.features as FilterFeature[]) || undefined,
        localCorporations: params.localCorporations
          ? params.localCorporations === "true"
            ? true
            : parseInt(params.localCorporations as string, 10)
          : undefined,
        opensBy: params.opensBy as FilterOpensBy,
        rating: (params.rating as RATING_FILTER_IDS) || undefined,
        tier: params.tier as Models.SubscriptionTier,
        weekendCare: (params.weekendCare as Models.WeekDay) || undefined,
      },
    };

    filtersRef.current = newFilters;
    globalFiltersAreActive.current = values(filtersRef.current.primary).some(
      (v) => v !== undefined,
    );
    secondaryFiltersAreActive.current = values(
      filtersRef.current.secondary,
    ).some((v) => v !== undefined);
    filterIsActive.current =
      globalFiltersAreActive.current || secondaryFiltersAreActive.current;

    return newFilters;
  }, []);

  const [state, setState] = React.useState<MapStore>(() => {
    const preInit: MapStore = {
      ...defaultState,
      searchString:
        (qs.parse(history.location.search).zip as string) ||
        defaultState.searchString,
      ...(root?.map || {}),
      ...initialState,
    };
    const query = qs.parse(search);

    if (
      primaryFilterParams.some(
        (f) => query[f] && preInit.filters.primary[f]?.toString() !== query[f],
      )
    ) {
      preInit.sponsoredFiltersOpened = false;
      shouldFitMapRef.current = true;
    }

    preInit.secondFiltersSequence = getSecondFiltersSequence(
      query,
      preInit.secondFiltersSequence,
    );
    preInit.filters = formatFilters(query);

    if (!facilityId && !globalFiltersAreActive.current) {
      if (query.lng && query.lat) {
        const c = {
          lat: parseFloat(query.lat as string),
          lng: parseFloat(query.lng as string),
        };
        preInit.anchorMapCenter = c;
        anchorMapCenterRef.current = c;
      }
    } else if (preInit.activeFacility && !globalFiltersAreActive.current) {
      const c = {
        lat: preInit.activeFacility.address.location.lat,
        lng: preInit.activeFacility.address.location.lon,
      };
      preInit.anchorMapCenter = c;
      anchorMapCenterRef.current = c;
    }
    return preInit;
  });

  const setUserPositionFetching = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        userPositionFetching: state,
      }));
    },
    [setState],
  );

  const setNeighbourhoodsShown = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        neighbourhoodsShown: state,
      }));
    },
    [setState],
  );

  const setShowPoisLegendModal = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        showPoisLegendModal: state,
      }));
    },
    [setState],
  );

  const setNotLoadedWarningOpened = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        notLoadedWarningOpened: state,
      }));
    },
    [setState],
  );

  const closeFilters = useCallback(() => {
    setState((prev) => ({
      ...prev,
      filtersState: {
        defaultSection: null,
        opened: false,
      },
    }));
  }, [setState]);

  const setAnchorMapCenter = useCallback(
    (coords: MapCenter) => {
      anchorMapCenterRef.current = coords;

      setState((prev) => ({
        ...prev,
        anchorMapCenter: coords,
      }));
    },
    [setState],
  );

  const openFilters = useCallback(
    (defaultSection: FiltersSections | null = null) => {
      setState((prev) => ({
        ...prev,
        filtersState: {
          defaultSection,
          opened: true,
        },
      }));
    },
    [setState],
  );

  const setActiveFacility = useCallback(
    (facility: Models.Facility | null) => {
      setState((prev) => ({
        ...prev,
        activeFacility: facility,
      }));
    },
    [setState],
  );

  const setEmptySearchVisible = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        emptySearchVisible: state,
      }));
    },
    [setState],
  );

  const setSponsoredFiltersOpened = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        sponsoredFiltersOpened: state,
      }));
    },
    [setState],
  );

  const setFilters = useCallback(
    (params: qs.ParsedQuery) => {
      let closeSponsor = false;

      if (
        primaryFilterParams.some(
          (f) =>
            params[f] && state.filters.primary[f]?.toString() !== params[f],
        )
      ) {
        closeSponsor = true;
        shouldReloadDataRef.current = true;
        shouldFitMapRef.current = true;
      }

      setState((prev) => ({
        ...prev,
        filters: formatFilters(params),
        secondFiltersSequence: getSecondFiltersSequence(
          params,
          state.secondFiltersSequence,
        ),
        sponsoredFiltersOpened: !prev.sponsoredFiltersOpened
          ? false
          : !closeSponsor,
      }));
    },
    [state.filters, state.secondFiltersSequence],
  );

  const filterFacilities = useCallback(
    (list: Models.FacilityCard[] = [], withZip = false) => {
      list = filterFacilitiesHelper(list, filtersRef.current);

      if (!/^\d/.test(searchStringRef.current.trim())) {
        list = list.filter((f) => {
          return normalizeString(f.searchIndex).includes(
            getFirstMeaningWord(searchStringRef.current.trim()),
          );
        });
      } else {
        if (withZip) {
          list = list.filter((f) => {
            return searchStringRef.current === f.address.zip;
          });
        }
      }

      return list;
    },
    [],
  );

  const updateFacilityCard = useCallback(
    async (id: string) => {
      const facilityResponse = await FACILITY_CARD_API.facilityCardsById({
        id,
      });
      const curIndex = state.facilities.findIndex((f) => f.id === id);

      if (curIndex !== -1) {
        const copy = state.facilities.slice();
        copy.splice(curIndex, 1, facilityResponse.data);

        setState((prev) => ({
          ...prev,
          facilities: copy,
          facilityList: filterFacilities(copy, true),
        }));
      }
    },
    [state.facilities, setState, filterFacilities],
  );

  const saveFacility = useCallback(
    async (facility: Models.Facility | null = state.activeFacility) => {
      if (!facility?.id) {
        return;
      }

      const response = await FACILITY_API.facilitiesByIdUpdate({
        facility,
        id: facility.id,
      });
      void updateFacilityCard(facility.id);

      setActiveFacility(response.data);
    },
    [setActiveFacility, updateFacilityCard],
  );

  const setShowFindToClaimDialog = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        showFindToClaimDialog: state,
      }));
    },
    [setState],
  );

  const setShowPoIs = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        showPoIs: state,
      }));
    },
    [setState],
  );

  const setHoveredFacilityId = useCallback(
    (id: string | null) => {
      setState((prev) => ({
        ...prev,
        hoveredFacilityId: id,
      }));
    },
    [setState],
  );

  const setDistrictShown = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        districtShown: state,
      }));
    },
    [setState],
  );

  const setFacilityList = useCallback(
    (facilityList: Models.FacilityCard[]) => {
      setState((prev) => ({
        ...prev,
        facilityList,
      }));
    },
    [setState],
  );

  const filtersCounter = useMemo(() => {
    return (
      values(state.filters.primary).filter((v) => Boolean(v)).length +
      values(state.filters.secondary).reduce((acc: number, cur) => {
        if (Boolean(cur)) {
          if (Array.isArray(cur)) {
            acc += cur.length;
          } else {
            acc += 1;
          }
        }

        return acc;
      }, 0)
    );
  }, [state.filters]);

  const getFacilityCardsList = useCallback(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));

    const MAX_DISTANCE = 1000 * 5 * DISTANCE_FACTOR;
    const MIN_DISTANCE = 1000 * 1 * DISTANCE_FACTOR;
    let locationParams = {};

    if (!globalFiltersAreActive.current) {
      locationParams = {
        location: `${anchorMapCenterRef.current.lat},${anchorMapCenterRef.current.lng}`,
        smartDistanceMax: MAX_DISTANCE,
        smartDistanceMin: MIN_DISTANCE,
        smartDistanceThreshold: 25,
      };
    }

    try {
      const facilityListResponse = await FACILITY_CARD_API.facilityCardsIndex({
        ...locationParams,
        accountIds: filtersRef.current.primary.accountId
          ? [filtersRef.current.primary.accountId]
          : undefined,
        addressState: filtersRef.current.primary.facilityName?.split("|")[2]
          ? [filtersRef.current.primary.facilityName?.split("|")[2]]
          : undefined,
        addressStreet: filtersRef.current.primary.address?.split("|")[0],
        addressZip: filtersRef.current.primary.phone?.split("|")[1]
          ? [filtersRef.current.primary.phone.split("|")[1]]
          : filtersRef.current.primary.address?.split("|")[1]
          ? [filtersRef.current.primary.address.split("|")[1]]
          : filtersRef.current.primary.facilityName?.split("|")[3]
          ? [filtersRef.current.primary.facilityName.split("|")[3]]
          : undefined,
        addressCountry: filtersRef.current.primary.facilityName?.split("|")[1]
          ? [filtersRef.current.primary.facilityName.split("|")[1]]
          : undefined,
        corporations: filtersRef.current.primary.corporation
          ? [filtersRef.current.primary.corporation]
          : undefined,
        end: filtersRef.current.primary.facilityName ? 50 : undefined,
        folder: filtersRef.current.primary.folder,
        ids: filtersRef.current.primary.ids?.split("|"),
        name: filtersRef.current.primary.facilityName?.split("|")[0],
        phone: filtersRef.current.primary.phone
          ? [filtersRef.current.primary.phone.split("|")[0]]
          : undefined,
        start: filtersRef.current.primary.facilityName ? 0 : undefined,
      });

      const facilityList = facilityListResponse.data.data;
      const filtered = filterFacilities(facilityList);
      const miles =
        facilityList.length &&
        facilityListResponse.data.meta.filterDistance === MIN_DISTANCE
          ? 1
          : 5;

      searchMilesRef.current = miles;
      setState((prev) => ({
        ...prev,
        emptySearchVisible: !filtered.length,
        facilities: facilityList,
        facilityList: filtered,
        searchMiles: miles,
      }));
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  }, [setState, filterFacilities]);

  const setSearchAreaButtonVisible = useCallback(
    (state: boolean) => {
      setState((prev) => ({
        ...prev,
        searchAreaButtonVisible: state,
      }));
    },
    [setState],
  );

  const setSearchString = useCallback(
    (str: string) => {
      searchStringRef.current = str;
      setState((prev) => ({
        ...prev,
        searchString: str,
      }));
    },
    [setState],
  );

  const fetchData = useCallback(async () => {
    setSearchAreaButtonVisible(false);
    await getFacilityCardsList();
  }, [setSearchAreaButtonVisible, getFacilityCardsList]);

  const clearFilters = useCallback(() => {
    const params = qs.parse(history.location.search);

    filterParams.forEach((f) => {
      params[f] = undefined;
    });

    history.push({
      search: qs.stringify(params),
    });
  }, [history]);

  return (
    <MapStoreContext.Provider
      value={[
        {
          ...state,
          anchorMapCenterRef,
          filterIsActive,
          filtersCounter,
          globalFiltersAreActive,
          searchMilesRef,
          secondaryFiltersAreActive,
          shouldFitMap: shouldFitMapRef,
          shouldReloadData: shouldReloadDataRef,
        },
        {
          clearFilters,
          closeFilters,
          fetchData,
          filterFacilities,
          getFacilityCardsList,
          openFilters,
          saveFacility,
          setActiveFacility,
          setAnchorMapCenter,
          setDistrictShown,
          setEmptySearchVisible,
          setFacilityList,
          setFilters,
          setHoveredFacilityId,
          setNeighbourhoodsShown,
          setNotLoadedWarningOpened,
          setSearchAreaButtonVisible,
          setSearchString,
          setShowFindToClaimDialog,
          setShowPoIs,
          setShowPoisLegendModal,
          setSponsoredFiltersOpened,
          setUserPositionFetching,
        },
      ]}
    >
      {children}
    </MapStoreContext.Provider>
  );
};

export function useMapStore(
  required: boolean,
): [MapStoreWithRefs, MapActions] | null;
export function useMapStore(): [MapStoreWithRefs, MapActions];
export function useMapStore(
  required = true,
): [MapStoreWithRefs, MapActions] | null {
  const store = React.useContext(MapStoreContext);

  if (!store && required) {
    throw new Error(
      "useMapStore must be used within a MapStoreContextProvider",
    );
  }

  return store;
}
