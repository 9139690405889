import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  container: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      "&:not(.header)": {
        width: 350,
      },
    },

    "&.header": {
      display: "flex",
    },

    " .formControlRoot": {
      backgroundColor: "white",
      borderRadius: 12,
      margin: 0,

      "& input": {
        height: 36,
        paddingLeft: 56,
        textAlign: "center",

        [theme.mediaRequests.mobile]: {
          height: 26,
        },
      },

      "&.header": {
        flex: "initial",
        width: 230,

        "& input": {
          height: 26,
          paddingLeft: 15,
          textAlign: "left",
        },

        "& + button": {
          height: 40,
          marginLeft: 12,
          marginTop: 0,
          width: "auto",
        },
      },
    },
  }),

  mainLocation: (theme) => ({
    color: theme.palette.yellow.main,
  }),

  button: (theme) => ({
    height: 50,
    marginTop: 12,
    whiteSpace: "nowrap",
    width: "100%",

    [theme.mediaRequests.mobile]: {
      height: 40,
    },
  }),
});
