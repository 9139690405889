import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  searchIcon: {
    color: theme.palette.primary.main,

    [theme.mediaRequests.mobile]: {
      "&:after": {
        backgroundColor: theme.palette.divider,
        content: '""',
        display: "flex",
        height: 20,
        position: "absolute",
        right: 0,
        width: 1,
      },
    },
  },

  searchInput: {
    [theme.mediaRequests.mobile]: {
      height: 34,
    },

    [theme.mediaRequests.notMobile]: {
      padding: "10px 0",
    },
  },

  mobileIcon: {
    "&:not(.filter)": {
      color: theme.palette.text.primary,
    },

    height: "1.3em",
    width: "1.3em",
  },

  searchInputRoot: {
    [theme.mediaRequests.mobile]: {
      fontSize: "1.3rem",
    },
  },

  searchFormControl: {
    [theme.mediaRequests.mobile]: {
      backgroundColor: "white",
      borderRadius: 8,
      boxShadow: "0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)",
      marginTop: 0,

      "&.filtersActive": {
        border: `1px solid ${theme.palette.divider}`,
      },
    },

    [theme.mediaRequests.notMobile]: {
      backgroundColor: "white",
      borderRadius: 17,
      margin: 0,
      maxWidth: 300,
      padding: "0 3px 0 15px",
    },
  },
});

export default createUseStyles<ITheme, keyof ReturnType<typeof styles>>(
  styles,
  { name: "SearchInput" },
);
