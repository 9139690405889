import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import qs from "query-string";
import { useQuery } from "react-query";

import useStyles from "./styles";
import {
  PrimaryFilters,
  FilterFeature,
  FilterAdditionalDesignation,
  SecondaryFilterParam,
} from "@models/common";
import { schoolTypesMap } from "@constants/school-types";
import { ratingFiltersMap } from "@constants/rating-filters";
import { timeFiltersMap } from "@constants/time-filters";
import { FiltersSections } from "@components/Filters";
import { useMapStore } from "@store/MapStore";

import { API, Models } from "@services/api";
import { useViewport } from "@hooks/useViewport";

type Chip = {
  key: string;
  label: string;
  value?: string;
  type: "primary" | "secondary";
};

const featuresMap: {
  [key in FilterFeature]: string;
} = {
  education: "Curriculum",
  faith: "Faith",
  meals: "Meals",
  media: "Multimedia",
  tuition: "Tuition",
  vr: "Virtual",
  language: "Language",
  backUp: "Backup care",
  videoStreaming: "Live video",
  mobileParentApp: "Parent app",
};

const additionalDesignationMap: {
  [key in FilterAdditionalDesignation]: string;
} = {
  "cooperative-school": "Co-op",
  nursery: "Nursery",
};

const childcareFiltersMap = {
  // all: "Any day",
  all: "Parent Pass",
  [Models.WeekDay.Monday]: "Mon svc",
  [Models.WeekDay.Tuesday]: "Tues svc",
  [Models.WeekDay.Wednesday]: "Wed svc",
  [Models.WeekDay.Thursday]: "Thurs svc",
  [Models.WeekDay.Friday]: "Fri svc",
  [Models.WeekDay.Saturday]: "Sat svc",
  [Models.WeekDay.Sunday]: "Sun svc",
};

export const HeaderFilters: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { isMobile } = useViewport();
  const [
    { filterIsActive, filters, secondFiltersSequence },
    { openFilters, clearFilters },
  ] = useMapStore();
  const [mobileChipsOpened, setMobileChipsOpened] = useState(false);
  const chipsCollapseRef = useRef<HTMLDivElement | null>(null);
  const [chipsCollapseLeft, setChipsCollapseLeft] = useState(0);
  const { data: corporations } = useQuery(
    "corporations",
    API.corporations.getList,
  );

  const classes = useStyles();

  const handleRemoveFilter = useCallback(
    (c: Chip) => {
      const params = qs.parse(search);

      return () => {
        history.push({
          search: qs.stringify({
            ...params,
            [c.key]: Array.isArray(params[c.key])
              ? (params[c.key] as string[]).filter((i) => i !== c.value)
              : undefined,
          }),
        });
      };
    },
    [search],
  );

  const handlerSetMobileChipsOpened = useCallback(
    (state: boolean) => {
      return () => {
        setMobileChipsOpened(state);
      };
    },
    [setMobileChipsOpened],
  );

  const handleChipClick = useCallback(
    (c: Chip) => {
      return () => {
        if (!isMobile) {
          openFilters(c.key as FiltersSections);
        } else {
          handleRemoveFilter(c)();
        }
      };
    },
    [isMobile, openFilters, handleRemoveFilter],
  );

  const handleMobileChipPanelClick = useCallback(
    (c: Chip) => {
      return () => {
        handleRemoveFilter(c)();
        setMobileChipsOpened(false);
      };
    },
    [handleRemoveFilter, setMobileChipsOpened],
  );

  useEffect(() => {
    if (chipsCollapseRef.current) {
      setChipsCollapseLeft(chipsCollapseRef.current.getBoundingClientRect().x);
    }
  }, [chipsCollapseRef.current]);

  const ActiveFilters = useMemo(() => {
    const primaryChips = Object.keys(filters.primary)
      .map((key) => {
        if (!filters.primary[key as keyof PrimaryFilters]) {
          return null;
        }

        let label;

        switch (key) {
          case "folder":
            label = "Favorites";
            break;
          case "accountId":
            label = "Claimed";
            break;
          case "corporation":
            label = corporations?.find(
              (c) => c.id === filters.primary[key],
            )?.shortName;
            break;
          case "phone":
            label = filters.primary.phone?.split("|")[0];
            break;
          case "ids":
            label = "Ids search";
            break;
          case "address":
            label = "Address";
            break;
          case "facilityName":
            label = filters.primary.facilityName?.split("|")[0]?.ellipsis(10);
            break;
          default:
            return null;
        }

        return {
          key,
          label,
          type: "primary",
        };
      })
      .filter((c) => c !== null) as Chip[];

    const secondaryChips = secondFiltersSequence
      .map((f) => {
        let label;

        switch (f.type) {
          case "facilityType":
            label = schoolTypesMap[f.value].chipName;
            break;
          case "features":
            label = featuresMap[f.value as FilterFeature];
            break;
          case "weekendCare":
            label =
              childcareFiltersMap[f.value as keyof typeof childcareFiltersMap];
            break;
          case "rating":
            label = ratingFiltersMap[f.value].chipName;
            break;
          case SecondaryFilterParam.AdditionalDesignation:
            label =
              additionalDesignationMap[f.value as FilterAdditionalDesignation];
            break;
          case SecondaryFilterParam.ClosesAfter:
          case SecondaryFilterParam.OpensBy:
            label = timeFiltersMap[f.value].chipName;
            break;
          case "tier":
            label = f.value.capitalize();
            break;
          case "localCorporations":
            if (f.value === "true") {
              label = "Sponsored";
            } else {
              label = corporations?.find(
                (c) => c.id.toString() === f.value,
              )?.shortName;
            }
            break;
          default:
            return null;
        }

        return {
          key: f.type,
          label,
          type: "secondary",
          value: f.value,
        };
      })
      .filter(Boolean) as Chip[];

    const allChips = primaryChips.concat(secondaryChips);
    const maxChips = isMobile ? 2 : 5;

    if (allChips.length > maxChips) {
      return (
        <div className={classes.mobileChipContainer} ref={chipsCollapseRef}>
          <Chip
            label={`${allChips.length} active filters`}
            onClick={handlerSetMobileChipsOpened(true)}
            className={classes.filterChip}
            variant="default"
          />
          {mobileChipsOpened && (
            <div className={classes.mobileChipsPopover}>
              <div
                className="overlay"
                onClick={handlerSetMobileChipsOpened(false)}
              />
              <div
                className="chips"
                style={{
                  left: chipsCollapseLeft + 5,
                }}
              >
                {allChips.slice(0, 6).map((chipData, i) => (
                  <Chip
                    key={`${chipData.key}-${i}`}
                    label={chipData.label}
                    onClick={handleMobileChipPanelClick(chipData)}
                    onDelete={handleMobileChipPanelClick(chipData)}
                    className={clsx(classes.filterChip, chipData.value, "chip")}
                    variant="default"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      );
    }

    return allChips
      .map((chipData, i) => {
        return (
          <Chip
            data-test={`${chipData.key}-filter-chip`}
            key={`${chipData.key}-${i}`}
            label={chipData.label}
            onClick={handleChipClick(chipData)}
            onDelete={handleRemoveFilter(chipData)}
            className={clsx(classes.filterChip, chipData.value)}
            variant="default"
          />
        );
      })
      .filter(Boolean);
  }, [filters, search, mobileChipsOpened, corporations]);

  if (!filterIsActive.current) {
    return null;
  }

  if (isMobile) {
    return (
      <div className={classes.root}>
        <div>{ActiveFilters}</div>

        <div className="clear" onClick={clearFilters}>
          Clear
        </div>
      </div>
    );
  }

  return <div className={classes.root}>{ActiveFilters}</div>;
};
