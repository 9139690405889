import keyBy from "lodash/keyBy";

export enum TIME_FILTER_IDS {
  By630AM = "by630am",
  By7AM = "by7am",
  After6PM = "after6pm",
}

export type TimeFilter = {
  id: TIME_FILTER_IDS;
  name: string;
  chipName: string;
};

export const TIME_FILTERS: TimeFilter[] = [
  {
    chipName: "6:30am",
    id: TIME_FILTER_IDS.By630AM,
    name: "School opens by 6:30am",
  },
  {
    chipName: "7:00am",
    id: TIME_FILTER_IDS.By7AM,
    name: "School opens by 7:00am",
  },
  {
    chipName: "6:00pm",
    id: TIME_FILTER_IDS.After6PM,
    name: "School closes after 6:00pm",
  },
];

export const timeFiltersMap = keyBy(TIME_FILTERS, "id");
