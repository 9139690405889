import React, { useState, Fragment } from "react";
import { css as emotionCss } from "@emotion/css";
import clsx from "clsx";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DropDownIcon from "@material-ui/icons/ArrowDropDown";
import DropUpIcon from "@material-ui/icons/ArrowDropUp";
import Badge from "@material-ui/core/Badge";

import { ROUTES } from "@constants";
import { createCss } from "./styles";
import MainMenu from "@components/MainMenu";
import SearchInput from "@components/SearchInput";
import { useUserStore } from "@store/UserStore";
import { useAppStore } from "@store/AppStore";
import { ReactGAEventWait } from "@helpers/ga";
import { SearchInput as HomepageSearchInput } from "@pages/Main/SearchInput";
import { HeaderFilters } from "@components/HeaderFilters";

import tealLogo from "@images/logo-teal-white.svg";
import { useMapStore } from "@store/MapStore";
import { useViewport } from "@hooks/useViewport";
import { API, Models } from "@services/api";
import { QUERIES } from "@constants/queries";
import { isCorpBenefit, isParent } from "@selectors";

interface IProps {
  fixed?: boolean;
  homepage?: {
    underSearch: boolean;
  };
}

const Header: React.FC<IProps> = ({ fixed, homepage }) => {
  const history = useHistory();
  const [{ location }] = useAppStore();
  const { isMobile } = useViewport();
  const [{ user, favorites, claimed }, { logout }] = useUserStore();
  const mapStore = useMapStore(false);
  const [accountAnchor, setAccountAnchor] = React.useState<null | HTMLElement>(
    null,
  );
  const [menuOpened, setMenuOpened] = useState(false);
  const { data: chats } = useQuery(
    [QUERIES.USER_CHATS, user?.id],
    () => API.contactRequests.getList({ parentUserId: user?.id }),
    { enabled: !!user?.id },
  );

  const css = createCss();

  if (isMobile && ROUTES.isMapView(location.pathname)) {
    return (
      <div
        css={css.searchContainer}
        className={clsx({
          filtersActive: mapStore?.[0].filterIsActive.current,
        })}
      >
        <SearchInput onMenuOpen={setMenuOpened} />

        <MainMenu open={menuOpened} onChange={setMenuOpened} />

        {mapStore?.[0].filterIsActive.current && <HeaderFilters />}
      </div>
    );
  }

  return (
    <Fragment>
      <div
        css={css.root}
        className={clsx({
          fixed,
          homepage: Boolean(homepage),
        })}
        data-test="page-header"
      >
        <div css={css.navigationContainer}>
          <nav css={css.navigation}>
            {!isMobile && (
              <Fragment>
                <Link
                  onClick={() => {
                    ReactGAEventWait({
                      action: "LogoClick",
                      category: "Header",
                    });
                  }}
                  to={ROUTES.HOMEPAGE}
                  css={css.logoWrapper}
                  data-test="Header-logo"
                >
                  <img src={tealLogo} alt="logo" />
                </Link>
                {homepage?.underSearch && (
                  <HomepageSearchInput position="header" />
                )}
                {ROUTES.isMapView(location.pathname) && (
                  <Fragment>
                    <SearchInput onMenuOpen={setMenuOpened} />

                    <button
                      css={css.link}
                      onClick={() => {
                        ReactGAEventWait({
                          action: "FiltersClick",
                          category: "Header",
                        });

                        mapStore?.[1].openFilters();
                      }}
                      data-test="filters-link"
                    >
                      <Badge
                        color="primary"
                        badgeContent={mapStore?.[0].filtersCounter}
                        invisible={!mapStore?.[0].filterIsActive.current}
                        classes={{
                          badge: emotionCss({
                            right: -10,
                          }),
                        }}
                      >
                        View filters
                      </Badge>
                    </button>
                  </Fragment>
                )}
              </Fragment>
            )}

            {isMobile && (
              <Fragment>
                <button
                  css={css.menuTrigger}
                  onClick={() => {
                    ReactGAEventWait({
                      action: "MobileMenuClick",
                      category: "Header",
                      label: menuOpened.toString(),
                    });

                    setMenuOpened((prevState) => !prevState);
                  }}
                  data-test="main-menu-btn"
                  id="main-menu-btn"
                >
                  <span></span>
                </button>
                <MainMenu onChange={setMenuOpened} open={menuOpened} />
              </Fragment>
            )}
          </nav>
          <nav
            css={css.navigation}
            className={clsx({
              right: true,
            })}
          >
            {!isMobile && (
              <Fragment>
                {!user && (
                  <Fragment>
                    <button
                      css={css.link}
                      onClick={() => {
                        ReactGAEventWait({
                          action: "LogInClick",
                          category: "Header",
                        });
                        history.push({
                          pathname: ROUTES.LOGIN_FORM,
                        });
                      }}
                      data-test="sign-in-link"
                    >
                      Log in
                    </button>
                    <button
                      css={css.link}
                      onClick={() => {
                        ReactGAEventWait({
                          action: "SignUpClick",
                          category: "Header",
                        });
                        history.push({
                          pathname: ROUTES.SIGNUP_FORM,
                        });
                      }}
                      data-test="sign-up-link"
                    >
                      Sign up
                    </button>
                    <button
                      css={css.link}
                      onClick={() => {
                        ReactGAEventWait({
                          action: "ContactSupportClick",
                          category: "Header",
                        });
                        history.push("/support");
                      }}
                      data-test="contact-support"
                    >
                      Contact support
                    </button>
                  </Fragment>
                )}

                {user && (
                  <Fragment>
                    <button
                      css={css.link}
                      data-test="my-account-link"
                      onClick={(event) => {
                        ReactGAEventWait({
                          action: "MyAccountDropdownClick",
                          category: "Header",
                        });

                        setAccountAnchor(event.currentTarget);
                      }}
                    >
                      My account
                      {Boolean(accountAnchor) ? (
                        <DropUpIcon />
                      ) : (
                        <DropDownIcon />
                      )}
                    </button>
                    <Menu
                      anchorEl={accountAnchor}
                      keepMounted
                      open={Boolean(accountAnchor)}
                      onClose={() => setAccountAnchor(null)}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      id="my-account-menu"
                      classes={{
                        list: emotionCss({
                          backgroundColor: "#131314",
                          color: "white",
                        }),
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          ReactGAEventWait({
                            action: "WeekendExperienceClick",
                            category: "Header",
                          });
                          setAccountAnchor(null);
                          history.push(ROUTES.ACTIVITIES);
                        }}
                        css={css.menuItem}
                      >
                        Account overview
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          ReactGAEventWait({
                            action: "MyProfileClick",
                            category: "Header",
                          });
                          setAccountAnchor(null);
                          history.push(ROUTES.PROFILE);
                        }}
                        css={css.menuItem}
                        className="gold lighted nested"
                        data-test="my-profile-link"
                      >
                        My profile
                      </MenuItem>
                      {!!claimed.length && (
                        <MenuItem
                          onClick={() => {
                            ReactGAEventWait({
                              action: "ClaimedFacilitiesClick",
                              category: "Header",
                            });
                            setAccountAnchor(null);
                            history.push("/map?accountId=-1");
                          }}
                          css={css.menuItem}
                          className="gold lighted nested"
                        >
                          Claimed schools ({claimed.length})
                        </MenuItem>
                      )}
                      {!!favorites.length && (
                        <MenuItem
                          onClick={() => {
                            ReactGAEventWait({
                              action: "MyFavoritesClick",
                              category: "Header",
                            });
                            setAccountAnchor(null);
                            history.push("/map?folder=favorites");
                          }}
                          css={css.menuItem}
                          className="gold lighted nested"
                        >
                          Favorite schools ({favorites.length})
                        </MenuItem>
                      )}
                      {!!chats?.length && (
                        <MenuItem
                          onClick={() => {
                            ReactGAEventWait({
                              action: "ChatsClick",
                              category: "Header",
                            });
                            setAccountAnchor(null);
                            history.push("/chats");
                          }}
                          data-test="chats-link"
                          css={css.menuItem}
                          className="gold lighted nested"
                        >
                          School chats ({chats?.length})
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          setAccountAnchor(null);
                          history.push("/support");
                        }}
                        css={css.menuItem}
                        className="gold lighted nested"
                      >
                        Contact support
                      </MenuItem>

                      {!claimed.length && [
                        <MenuItem css={css.menuItem} key="pdo-service">
                          Parent Pass
                        </MenuItem>,
                        isParent(user) && (
                          <MenuItem
                            onClick={() => {
                              ReactGAEventWait({
                                action: "ChildrenListClick",
                                category: "Header",
                              });
                              setAccountAnchor(null);
                              history.push(ROUTES.CHILDREN_LIST);
                            }}
                            data-test="child-profile-link"
                            css={css.menuItem}
                            className="gold lighted nested"
                            key="child-profile"
                          >
                            Child profile(s)
                          </MenuItem>
                        ),
                        isParent(user) && (
                          <MenuItem
                            onClick={() => {
                              ReactGAEventWait({
                                action: "PaymentMethodClick",
                                category: "Header",
                              });
                              setAccountAnchor(null);
                              history.push(ROUTES.WALLET);
                            }}
                            data-test="payments-link"
                            css={css.menuItem}
                            className="gold lighted nested"
                            key="payment-method"
                          >
                            Payment method
                          </MenuItem>
                        ),
                        <MenuItem
                          onClick={() => {
                            ReactGAEventWait({
                              action: "ReservationHistoryClick",
                              category: "Header",
                            });
                            setAccountAnchor(null);

                            if (
                              user.account.type ===
                              Models.AccountType.CorpBenefit
                            ) {
                              history.push(
                                `/dashboard/corporations/${user.account.id}/reservations`,
                              );
                            } else {
                              history.push(ROUTES.ACTIVITIES);
                            }
                          }}
                          data-test="weekend-care-history-link"
                          css={css.menuItem}
                          className="gold lighted nested"
                          key="reservation-history"
                        >
                          Reservation history
                        </MenuItem>,
                        isParent(user) && (
                          <MenuItem
                            onClick={() => {
                              ReactGAEventWait({
                                action: "RegistrationFormsClick",
                                category: "Header",
                              });
                              setAccountAnchor(null);
                              history.push("/registration-forms");
                            }}
                            data-test="registration-forms-link"
                            css={css.menuItem}
                            className="gold lighted nested"
                            key="registaration-forms"
                          >
                            Registration forms
                          </MenuItem>
                        ),
                      ]}

                      <MenuItem />

                      <MenuItem
                        onClick={() => {
                          ReactGAEventWait({
                            action: "LogOutClick",
                            category: "Header",
                          });
                          setAccountAnchor(null);
                          logout();
                        }}
                        data-test="logout-link"
                        css={css.menuItem}
                      >
                        Log out
                      </MenuItem>
                    </Menu>
                  </Fragment>
                )}
              </Fragment>
            )}

            {ROUTES.isMapView(location.pathname) && (
              <button
                css={[css.link, css.findLink]}
                onClick={() => {
                  mapStore?.[1].setShowFindToClaimDialog(true);
                }}
                data-test="claim-facility-link"
              >
                Facility search
              </button>
            )}
          </nav>
        </div>

        {ROUTES.isMapView(location.pathname) &&
          mapStore?.[0].filterIsActive.current && <HeaderFilters />}
      </div>
    </Fragment>
  );
};

export default Header;
